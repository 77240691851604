<template>
  <div class="logout">Logging out...</div>
</template>

<script>
import api from "../../assets/js/api";

export default {
  mounted(){
    api.logout().then((response)=>{
      if(response.data.success){
        this.$router.push("/");
      }
    });
  }
}
</script>

<style>

</style>