<template>
  <div class="auth">
    <v-app>
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>