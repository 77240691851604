<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialogOpen" max-width="400px" persistent>
        <v-card>
          <v-card-title>
            <span class="text-h3 text-wrap">Projekt umbenennen</span>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="renameProject()" ref="renameForm">
              <v-text-field
                v-model="name"
                :rules="getRules({ required: true })"
                label="Projektname"
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogOpen = false"> Abbrechen </v-btn>
            <v-btn color="primary" @click="renameProject()"> Umbenennen </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
const variables = require("../../../assets/js/variables");
import { projectEditorBus } from "../../../main";

export default {
  props: ["opened", "name", "id"],
  data: () => ({}),
  computed: {
    dialogOpen: {
      get() {
        return this.opened;
      },
      set(newVal) {
        this.changeDialog(newVal);
      },
    },
  },
  methods: {
    changeDialog(val) {
      projectEditorBus.$emit("changeRenameProjectMenu", {
        opened: val,
      });
    },
    renameProject() {
      if (this.$refs.renameForm.validate()) {
        projectEditorBus.$emit("renameProject", {
          projectID: this.id,
          projectName: this.name,
        });
      }
    },
    getRules(rules) {
      return variables.getRules(rules);
    },
  },
};
</script>

<style>
</style>