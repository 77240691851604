import Vue from 'vue';
import VueRouter from 'vue-router';
import adminDashboard from '../views/Admin/AdminDashboard';
import Admin from '../components/Admin/Admin';
import adminRecycleBin from '../views/Admin/AdminRecycleBin';
import adminSettings from '../views/Admin/AdminSettings';
import Logout from '../views/Auth/Logout';
import ProjectEditor from '../views/Admin/ProjectEditor';
import Registration from '../views/User/Registration';
import Auth from '../views/Auth/Auth';
import Login from '../components/User/Auth/Login';
import Signup from '../components/User/Auth/Signup'
import LandingPage from '../views/Landingpage/Landingpage';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'DefaultRedirect',
    component: LandingPage
  },
  {
    path: '/admin',
    component: Admin,
    children: [{
      path: '/admin/dashboard',
      name: 'AdminDashboard',
      component: adminDashboard
    },
    {
      path: '/admin/settings',
      name: 'AdminSettings',
      component: adminSettings
    },
    {
      path: '/admin/recyclebin',
      name: 'AdminRecycleBin',
      component: adminRecycleBin
    },
    {
      path: '/admin/projectEditor/:project_id',
      name: 'ProjectEditor',
      component: ProjectEditor,
      props: true
    },
    {
      path: '/admin/projectEditor/',
      name: 'ProjectEditorRedirect',
      redirect: '/admin/dashboard'
    },
    {
      path: '',
      name: 'defaultRedirect',
      redirect: '/admin/dashboard'
    }]
  },
  {
    path: '/registration/:voucher',
    name: 'Registration Form',
    component: Registration,
    props: true
  },

  {
    path: '/auth',
    name: "Auth",
    component: Auth,
    children: [
      {
        path: '/auth/signup/',
        name: 'User Registration',
        component: Signup
      },
      {
        path: '/auth/login/',
        name: 'User Login',
        component: Login
      },
      {
        path: '',
        name: 'Auto Redirect',
        component: Login
      }
    ]
  },
  {
    path: '/admin/projectEditor/',
    name: 'Registration Form Redirect',
    redirect: '/'
  },
  {
    path: '/logout',
    name: Logout,
    component: Logout
  }
]

const router = new VueRouter({
  routes: routes,
  mode: 'history'
});

export default router
