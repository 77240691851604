<template>
  <div class="login">
    <v-card width="100%">
      <v-form @submit.prevent="login()" ref="authForm">
        <v-card-text>
          <v-text-field
            label="E-Mail"
            type="email"
            v-model="email"
            :rules="getRules({ required: true, email: true })"
            :error-messages="credentialError"
            @keydown="wrongCredentials = false"
          ></v-text-field>
          <v-text-field
            label="Passwort"
            type="password"
            v-model="password"
            :rules="getRules({ required: true, minString: 6 })"
            :error-messages="credentialError"
            @keydown="wrongCredentials = false"
          ></v-text-field>
        </v-card-text>
        <v-card-actions
          :class="
            $vuetify.breakpoint.xs
              ? 'd-xs-flex flex-column-reverse align-start pt-0'
              : ''
          "
        >
          <div
            :class="`alternative-btn d-flex flex-column align-start ${
              $vuetify.breakpoint.xs ? 'flex-column-reverse' : 'flex-column'
            }`"
          >
            <span class="alternative-hint">Noch kein Konto?</span>
            <v-btn text to="/auth/signup" class="pl-0">Konto erstellen</v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :class="$vuetify.breakpoint.xs ? 'mb-2' : 'mt-2'"
            type="submit"
            >Anmelden</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import api from "../../../assets/js/api";
const variables = require("../../../assets/js/variables");

export default {
  props: ["embedded"],
  data: () => ({
    email: "",
    password: "",
    wrongCredentials: false,
  }),
  computed: {
    credentialError() {
      if (this.wrongCredentials) {
        return "Benutzername oder Passwort falsch";
      }
      return null;
    },
  },
  methods: {
    login() {
      if (this.$refs.authForm.validate()) {
        api
          .login(this.email, this.password)
          .then((response) => {
            if (response.data.success) {
              if (this.embedded) {
                window.location.reload();
              } else {
                this.$router.push("/admin/dashboard");
              }
            }
          })
          .catch((err) => {
            if (err.response.data.msg === "Wrong username or password") {
              this.wrongCredentials = true;
            }
          });
      }
    },
    getRules(rules) {
      return variables.getRules(rules);
    },
  },
};
</script>

<style scoped lang="scss">
.login {
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.alternative-btn {
  .alternative-hint {
    font-size: 0.9em;
  }
}
</style>