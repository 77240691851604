<template>
  <v-app-bar app>
    <v-toolbar-title>
      <v-img
        src="../../assets/images/logo_border.png"
        contain
        width="180"
        class="app_bar_logo"
        @click="handleClickLogo"
      />
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn color="primary" to="/auth/signup" text outlined class="mr-5"
      >Registrieren</v-btn
    >
    <v-btn color="primary" to="/admin/dashboard"> Zum Dashboard</v-btn>
  </v-app-bar>
</template>
<script>
export default {
  methods: {
    handleClickLogo() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.app_bar_logo {
  cursor: pointer;
}
</style>