<template>
  <div class="signup">
    <v-card width="100%">
      <v-form @submit.prevent="register()" ref="authForm">
        <v-card-text>
          <v-text-field
            v-model="firstName"
            label="Vorname"
            :rules="getRules({ required: true, minString: 3, maxString: 255 })"
          ></v-text-field>
          <v-text-field
            v-model="secondName"
            label="Nachname"
            :rules="getRules({ required: true, minString: 3, maxString: 255 })"
          ></v-text-field>
          <v-text-field
          :error-messages="emailError"
          @keydown="userExists=false"
            v-model="email"
            label="E-Mail"
            type="email"
            :rules="getRules({ required: true, email: true })"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Passwort"
            type="password"
            :rules="getRules({ required: true, minString: 6 })"
          ></v-text-field>
        </v-card-text>
        <v-card-actions
          :class="
            $vuetify.breakpoint.xs
              ? 'd-xs-flex flex-column-reverse align-start pt-0'
              : ''
          "
        >
          <div
            :class="`alternative-btn d-flex flex-column align-start ${
              $vuetify.breakpoint.xs ? 'flex-column-reverse' : 'flex-column'
            }`"
          >
            <span class="alternative-hint">Bereits registriert?</span>
            <v-btn text to="/auth/login" class="pl-0"> Zum Login</v-btn>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :class="$vuetify.breakpoint.xs ? 'mb-2' : 'mt-2'"
            >Konto erstellen</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import api from "../../../assets/js/api";
const variables = require("../../../assets/js/variables");

export default {
  data: () => ({
    firstName: "",
    secondName: "",
    email: "",
    password: "",
    userExists:false
  }),
  computed:{
    emailError(){
      if(this.userExists){
        return "Diese E-Mail wurde bereits registriert"
      }
      return null
    }
  },
  methods: {
    register() {
      if (this.$refs.authForm.validate()) {
        api
          .signup(this.firstName, this.secondName, this.email, this.password)
          .then((response) => {
            if (response.data.success) {
              this.$router.push("/admin/dashboard");
            }
          })
          .catch((err) => {
            if (err.response.data.msg === "User already exists") {
              this.userExists = true;
            }
          });
      }
    },
    getRules(rules) {
      return variables.getRules(rules);
    },
  },
};
</script>

<style scoped lang="scss">
.signup {
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.alternative-btn {
  .alternative-hint {
    font-size: 0.9em;
  }
}
</style>