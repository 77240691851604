<template>
  <v-card :width="$vuetify.breakpoint.xs ? '90%' : '250px'" class="mr-2 mb-4">
    <v-card-title>{{
      projectDetails.project_name | projectCardSnippet
    }}</v-card-title>
    <v-card-subtitle
      >Erstellt am
      {{
        new Date(projectDetails.creation_time).toLocaleDateString()
      }}</v-card-subtitle
    >
    <v-card-text>
      <p class="body-1 mb-0">{{ projectDetails.counted_dates }} Termine</p>
      <p class="body-1 mb-0">
        {{ projectDetails.counted_registrations }} Anmeldungen
      </p>
      <p class="body-1 mb-0">{{ projectDetails.counted_vouchers }} Vouchers</p>
      <p class="body-1 mb-0">
        {{ projectDetails.counted_collaborations }} Freigaben
      </p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon @click="editProject(projectDetails.project_id,projectDetails.user_permission)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="projectDetails.user_permission == 'owner'"
        @click="showShareDialog(projectDetails.project_id)"
      >
        <v-icon>mdi-share</v-icon>
      </v-btn>
      <v-btn
        icon
        v-if="projectDetails.user_permission == 'owner'"
        @click="deleteProject(projectDetails.project_id)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["projectDetails"],
  methods: {
    deleteProject(projectID) {
      this.$parent.$emit("deleteProject", projectID);
    },
    editProject(projectID,editMode) {
      this.$parent.$emit("editProject", {projectID:projectID, permission:editMode});
    },
    showShareDialog(projectID) {
      this.$parent.$emit("changeShareDialog", {
        show: true,
        projectID: projectID,
      });
    },
  },
};
</script>

<style>
</style>