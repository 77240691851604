<template>
  <div class="contact">
    <v-snackbar v-model="contactFormSnackBar" color="success" timeout="5000" top>
      Die Nachricht wurde übermittelt
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="contactFormSnackBar = false"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <span class="text-h3">Kontaktformular</span>
    <br />
    <span class="text-b1"
      >Wenn Sie Fragen an den Betreiber dieser Umfrage haben, können Sie ihn mit
      diesem Kontaktformular erreichen.</span
    >
    <v-card max-width="800px" class="mx-auto mt-5">
      <v-form @submit.prevent="submitContactForm" ref="contactForm">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="formDetails.firstName"
                label="Vorname"
                type="text"
                :rules="
                  getRules({ required: true, minString: 3, maxString: 60 })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="formDetails.secondName"
                label="Nachname"
                type="text"
                :rules="
                  getRules({ required: true, minString: 3, maxString: 60 })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="formDetails.email"
                label="E-Mail"
                type="email"
                :rules="getRules({ required: true, email: true })"
              ></v-text-field>
            </v-col>
           
            <v-col cols="12" class="mx-auto">
              <v-textarea
                v-model="formDetails.problem"
                label="Beschreibung des Anliegens"
                type="text"
                :rules="
                  getRules({ required: true, minString: 3, maxString: 250 })
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit">Absenden</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
const variables = require("../../../assets/js/variables");
import { registerBus } from "../../../main";

export default {
  data: () => ({
    formDetails: {
      firstName: "",
      secondName: "",
      email: "",
      problem: "",
      voucherID: "",
    },
    contactFormSnackBar: false,
  }),
  created() {
    registerBus.$on("contactFormSubmitted", () => {
      this.$refs.contactForm.reset();
      this.contactFormSnackBar = true;
    });
  },
  beforeDestroy() {
    registerBus.$off("contactFormSubmitted");
  },
  methods: {
    getRules(rules) {
      return variables.getRules(rules);
    },
    submitContactForm() {
      if (this.$refs.contactForm.validate()) {
        this.formDetails.voucherID = this.$route.params.voucher;
        registerBus.$emit("submitContactForm", this.formDetails);
      }
    },
  },
};
</script>

<style>
</style>