<template>
  <v-app>
    <AppBar />
    <v-main>
      <div class="demoContainer">
        <div class="textContainer">
          <h2 class="text-h2">Einfache und sichere Verwaltung Ihrer Anlässe</h2>
          <h3 class="text-subtitle-1">
            Events lassen sich in Sekundenschnelle erstellen. Laden Sie Personen
            ein und legen Sie fest, wie viele Teilnehmende erlaubt sind. Falls
            Sie in einem Team arbeiten, können Sie die Planung aufteilen und
            weitere Teammitglieder am Projekt beteiligen.
          </h3>
          <v-btn color="primary" to="/admin/dashboard" class="mt-5 mr-5">
            Zum Dashboard</v-btn
          >
          <v-btn color="primary" to="/auth/signup" text outlined class="mt-5"
            >Registrieren</v-btn
          >
        </div>
        <div class="imageContainer">
          <v-img
            src="../../assets/images/screenshot_1.png"
            :max-width="imageSize"
          ></v-img>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./AppBar.vue";
export default {
  components: { AppBar },
  computed: {
    imageSize() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 650;
      } else {
        return 450;
      }
    },
  },
};
</script>

<style>
.demoContainer {
  margin: 0 auto;
  margin-top: 150px;
  padding: 0 50px;
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1000px) {
  .demoContainer {
    flex-direction: column;
     margin-top: 50px;
  }
}
.textContainer {
  max-width: 450px;
}
</style>