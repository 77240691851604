<template>
  <div class="calendar">
    <p class="text-h3">Terminkalender</p>
    <v-row class="fill-height" style="position: relative">
      <v-col :class="calendarDisabled ? '' : 'calendar-disabled'">
        <v-sheet>
          <v-toolbar flat :height="calendarToolbarHeight">
            <div class="d-flex flex-row flex-wrap my-auto">
              <v-btn outlined color="grey darken-2" @click="setToday">
                Heute
              </v-btn>
              <div>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
                  class="mr-1"
                >
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <v-toolbar-title v-if="$refs.calendar" class="mr-5">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Tag</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Woche</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Monat</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 Tage</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="500">
          <v-calendar
            interval-height="30"
            :locale="locale"
            ref="calendar"
            v-model="focus"
            color="primary"
            :event-color="getEventColor"
            :events="events"
            :type="type"
            :short-months="false"
            :short-weekdays="false"
            :start="today"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            :first-interval="firstInterval"
            :interval-count="intervalCount"
            :weekdays="weekdays"
          ></v-calendar>

          <RegisterMenu
            :selectedOpen="selectedOpen"
            :selectedEvent="selectedEvent"
            :selectedElement="selectedElement"
            :registrations="$props.registrationData"
          />
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { registerBus } from "../../../main";
import RegisterMenu from "./RegisterMenu";

export default {
  props: ["dates", "calendarDisabled", "registrationData"],
  components: { RegisterMenu },
  data: () => ({
    focus: null,
    type: "week",
    typeToLabel: {
      month: "Monat",
      week: "Woche",
      day: "Tag",
      "4day": "4 Tage",
    },
    name: null,
    details: null,
    start: null,
    end: null,
    currentlyEditing: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    newDateDialogShow: false,
    newDateMode: "create",
    screenWidth: null,
    today: new Date(),
  }),
  computed: {
    firstEvent() {
      let sortedDates = this.$props.dates.slice().sort((a,b) => (a.date_start > b.date_start) ? 1 : ((b.date_start > a.date_start) ? -1 : 0));
      if(sortedDates.length>0){
        return new Date(sortedDates[0].date_start);
      }
      else {return new Date();}
    },
    weekdays() {
      let weekdays = [1, 2, 3, 4, 5];
      this.$props.dates.forEach((date) => {
        date = new Date(date.date_start);
        if (date.getDay() == 0 || date.getDay() == 6) {
          weekdays = [1, 2, 3, 4, 5, 6, 0];
        }
      });
      return weekdays;
    },
    firstInterval() {
      let firstInterval = 8;

      this.$props.dates.forEach((date) => {
        date = new Date(date.date_start);
        if (date.getHours() < firstInterval) {
          firstInterval = date.getHours();
        }
      });

      return firstInterval;
    },
    intervalCount() {
      let firstInterval = this.firstInterval;
      let intervalCount = 20 - firstInterval;

      this.$props.dates.forEach((date) => {
        date = new Date(date.date_end);
        if (date.getHours() < firstInterval) {
          intervalCount = 24 - this.firstInterval;
        } else if (intervalCount < date.getHours() - this.firstInterval) {
          intervalCount = date.getHours() - this.firstInterval;
        }
      });

      return intervalCount;
    },
    events() {
      let events = this.$props.dates;

      events = events.map(({ date_start: start, date_end: end, ...rest }) => ({
        start,
        end,
        ...rest,
      }));

      events = events.map((e) => {
        return {
          ...e,
          start: this.formatDate(e.start),
          end: this.formatDate(e.end),
          name: `Noch ${e.date_free_spaces.toString()} freie Plätze`,
        };
      });
      return events;
    },

    locale() {
      return Intl.DateTimeFormat().resolvedOptions().locale;
    },
    calendarToolbarHeight() {
      if (this.screenWidth < 350) {
        return "130px";
      } else if (this.screenWidth < 400) {
        return "110px";
      } else if (this.screenWidth < 650) {
        return "100px";
      } else {
        return "50px";
      }
    },
  },
  mounted() {
    this.$refs.calendar.move(0);

    this.$refs.calendar.scrollToTime("08:00");
    setTimeout(() => {
      this.focus = this.firstEvent;
    }, 1000);
  },
  created() {
    if (this.$vuetify.breakpoint.xs) {
      this.type = "day";
    }
    registerBus.$on("changeRegisterMenu", (val) => {
      this.selectedOpen = val;
    });
  },

  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    formatDate(date) {
      date = new Date(date);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${(
        "0" + date.getHours()
      ).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
    },
    getEventColor(event) {
      let matches = [];
      matches = this.$props.registrationData.filter((registration) => {
        return registration.date_id == event.date_id;
      });
      if (matches.length == 0) {
        return "primary";
      } else {
        return "#e26318";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-disabled::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #ffffff, $alpha: 0.7);
}
</style>