<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    :permanent="$vuetify.breakpoint.mdAndUp ? true : false"
  >
    <v-list
      :height="
        $vuetify.breakpoint.mdAndUp
          ? bigMeasures.barHeight
          : smallMeasures.barHeight
      "
      elevation="4"
      class="mb-2"
    >
      <v-list-item>
        <v-img
          src="../../assets/images/logo_border.png"
          :max-width="
            $vuetify.breakpoint.mdAndUp
              ? bigMeasures.logoWidth
              : smallMeasures.logoWidth
          "
          contain
        ></v-img>
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item class="mb-2">
        <div class="navigation-user-info">
          <p class="text-h5 mb-0">{{userName}}</p>
          <p class="text-body-1 mb-0">{{userEmail}}</p>
        </div>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item-group>
        <v-list-item
          v-for="(item, index) in navigationItems"
          :key="index"
          :to="item[2] == '#' ? null : item[2]"
          @click="item[2] == '#' ? renameProject() : null"
        >
          <v-list-item-icon>
            <v-icon v-text="item[1]"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{ item[0] }}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { navBus, projectEditorBus } from "../../main";

export default {
  props: ["smallMeasures", "bigMeasures", "editMode", "userDetails"],
  data: () => ({
    navigationItems: [],
    navigationItemsMain: [
      ["Meine Projekte", "mdi-account", "/admin/dashboard"],
      ["Einstellungen", "mdi-cog", "/admin/settings"],
      ["Papierkorb", "mdi-delete", "/admin/recyclebin"],
      ["Logout", "mdi-logout", "/logout"],
    ],
    navigationItemsEditor: [
      ["Zurück zum Dashboard", "mdi-arrow-left", "/admin/dashboard"],
      ["Projekt umbenennen", "mdi-pencil", "#"],
    ],
    navigationItemsReader: [
      ["Zurück zum Dashboard", "mdi-arrow-left", "/admin/dashboard"],
    ],
    drawer: null,
    navTiles: "",
  }),

  created() {
    projectEditorBus.$on("changeEditMode", (mode) => {
      if (this.navTiles == "projectEditor") {
        if (mode === "read") {
          this.navigationItems = this.navigationItemsReader;
        } else {
          this.navigationItems = this.navigationItemsEditor;
        }
      } else {
        this.navigationItems = this.navigationItemsMain;
      }
    });
    navBus.$on("toggleNavDrawer", (data) => {
      this.drawer = data;
    });
    navBus.$on("changeNavigationTiles", (data) => {
      this.navTiles = data;
      if (this.navTiles == "projectEditor") {
        if (this.$props.editMode === "read") {
          this.navigationItems = this.navigationItemsReader;
        } else {
          this.navigationItems = this.navigationItemsEditor;
        }
      } else {
        this.navigationItems = this.navigationItemsMain;
      }
    });
  },
  methods: {
    renameProject() {
      projectEditorBus.$emit("changeRenameProjectMenu", { open: true });
    },
  },
  computed: {
    userName() {
      if (this.$props.userDetails) {
        return this.$props.userDetails.username;
      }
      return "";
    },
    userEmail() {
      if (this.$props.userDetails) {
        return this.$props.userDetails.email;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-user-info {
  display: flex;
  flex-direction: column;
  & > * {
    margin: 0;
  }
}
</style>