<template>
  <div id="voucherPanel">
    <v-dialog v-model="deleteVoucherDialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Voucher löschen? </v-card-title>
        <v-card-text> Soll dieser Voucher gelöscht werden?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteVoucherDialog = false"> Nein </v-btn>
          <v-btn color="primary" @click="deleteVoucher()"> Ja </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="createVoucherDialog" max-width="400px" persistent>
      <v-card>
        <v-card-title>
          <span v-if="editVoucherDialog" class="text-h3 text-wrap">
            Voucher bearbeiten
          </span>
          <span v-else class="text-h3 text-wrap">Voucher erstellen</span>
        </v-card-title>
        <v-form
          ref="editVoucherForm"
          @submit.prevent="editVoucherDialog ? editVoucher() : createVoucher()"
        >
          <v-card-text>
            <v-text-field
              :rules="getRules({ maxString: 255 })"
              v-model="comment"
              label="Kommentar"
            >
            </v-text-field>
            <v-text-field
              :rules="
                getRules({ required: true, maxNumber: 255, minNumber: 1 })
              "
              v-model="submissions"
              type="number"
              label="Registrationen"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeVoucherDialog()"> Abbrechen </v-btn>
            <v-btn color="primary" type="submit"> Speichern </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <p class="text-h3">Einladungen</p>
    <div class="vouchers">
      <v-data-table
        mobile-breakpoint="1200"
        hide-default-footer
        disable-filtering
        disable-pagination
        v-if="vouchers"
        :headers="tableHeaders"
        :items="vouchers"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.copy="props">
          <v-btn
            class="mx-2"
            icon
            @click="copyToClipboard(props.item.voucher_id)"
          >
            <v-icon dark>mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="props" v-if="$props.editMode !== 'read'">
          <v-tooltip top :disabled="props.item.voucher_all_submissions ===
                    props.item.voucher_remaining_submissions">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  class="mx-2"
                  icon
                  @click="deleteVoucherHandler(props.item)"
                  :disabled="
                    props.item.voucher_all_submissions !==
                    props.item.voucher_remaining_submissions
                  "
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Kann nicht gelöscht werden, solange noch Anmeldungen über diese Einladung bestehen</span>
          </v-tooltip>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="props" v-if="$props.editMode !== 'read'">
          <v-btn class="mx-2" icon @click="editVoucherHandler(props.item)">
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-btn
        icon
        @click="createVoucherHandler()"
        v-if="$props.editMode !== 'read'"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </div>
  </div>
</template>

<script>
import { projectEditorBus } from "../../../main";
const variables = require("../../../assets/js/variables");

export default {
  data: () => ({
    deleteVoucherDialog: false,
    createVoucherDialog: false,
    editVoucherDialog: false,
    deleteVoucherID: "",
    createVoucherID: "",
    submissions: "",
    comment: "",
    selectedVoucher: {},
  }),
  props: ["voucherData", "editMode"],
  created() {
    projectEditorBus.$on("changeDeleteVoucherDialog", (val) => {
      this.deleteVoucherDialog = val;
    });

    projectEditorBus.$on("changeCreateVoucherDialog", (val) => {
      this.createVoucherDialog = val;
      this.editVoucherDialog = false;
    });
  },
  mounted() {},
  computed: {
    tableHeaders() {
      if (this.$props.editMode === "read") {
        return [
          { text: "Kopieren", value: "copy", sortable: false },
          { text: "Einladungslink", value: "voucher_id" },
          { text: "Anmeldungen Total", value: "voucher_all_submissions" },
          { text: "Anmeldungen Frei", value: "voucher_remaining_submissions" },
          { text: "Bemerkung", value: "voucher_comment" },
        ];
      } else {
        return [
          { text: "Kopieren", value: "copy", sortable: false },
          { text: "Link", value: "voucher_id" },
          { text: "Anmeldungen Total", value: "voucher_all_submissions" },
          { text: "Anmeldungen Frei", value: "voucher_remaining_submissions" },
          { text: "Bemerkung", value: "voucher_comment" },
          { text: "Bearbeiten", value: "edit", sortable: false },
          { text: "Löschen", value: "delete", sortable: false },
        ];
      }
    },
    vouchers() {
      let vouchers = this.$props.voucherData.map((e) => {
        return {
          ...e,
          voucher_id: `https://surveyscope.net/registration/${e.voucher_id}`,
        };
      });
      return vouchers;
    },
  },
  methods: {
    getRules(rules) {
      return variables.getRules(rules);
    },
    createVoucherHandler() {
      this.comment = "";
      this.submissions = "";
      this.createVoucherDialog = true;
    },
    deleteVoucherHandler(voucher) {
      this.selectedVoucher = voucher;
      this.deleteVoucherDialog = true;
    },
    editVoucherHandler(voucher) {
      this.comment = voucher.voucher_comment;
      this.submissions = voucher.voucher_all_submissions;
      this.selectedVoucher = voucher;
      this.createVoucherDialog = true;
      this.editVoucherDialog = true;
    },
    closeVoucherDialog() {
      this.$refs.editVoucherForm.resetValidation();
      this.createVoucherDialog = false;
      this.editVoucherDialog = false;
    },
    createVoucher() {
      if (this.$refs.editVoucherForm.validate()) {
        projectEditorBus.$emit("createVoucher", {
          comment: this.comment,
          submissions: this.submissions,
        });
      }
    },
    editVoucher() {
      if (this.$refs.editVoucherForm.validate()) {
        projectEditorBus.$emit("updateVoucher", {
          voucherID: this.selectedVoucher.voucher_id.split("/")[
            this.selectedVoucher.voucher_id.split("/").length - 1
          ],
          comment: this.comment,
          submissions: this.submissions,
        });
      }
    },
    copyToClipboard(text) {
      let el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    deleteVoucher() {
      let voucher = this.selectedVoucher.voucher_id.split("/")[
        this.selectedVoucher.voucher_id.split("/").length - 1
      ];
      projectEditorBus.$emit("deleteVoucher", voucher);
    },
  },
  beforeDestroy() {
    projectEditorBus.$off("changeDeleteVoucherDialog");
    projectEditorBus.$off("changeCreateVoucherDialog");
  },
};
</script>

<style scoped lang="scss">
$breakpoints: (
  550px: 350px,
  450px: 250px,
  350px: 150px,
);

@each $screenWidth, $boxWidth in $breakpoints {
  @media screen and (max-width: $screenWidth) {
    ::v-deep .v-data-table__mobile-row__cell {
      max-width: $boxWidth;
      overflow: scroll;
      max-height: 48px;
      line-height: 48px;
      white-space: nowrap;
    }
  }
}
</style>