<template>
  <div id="registrationsPanel">
    <v-dialog v-model="deleteRegistrationDialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Registration löschen? </v-card-title>
        <v-card-text> Soll diese Registration gelöscht werden?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteRegistrationDialog = false"> Nein </v-btn>
          <v-btn color="primary" @click="deleteRegistration()"> Ja </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <p class="text-h3">Registrationen</p>
    <div class="vouchers">
      <v-data-table
        v-if="registrations"
        :headers="tableHeaders"
        :items="registrations"
        hide-default-footer
        disable-filtering
        disable-pagination
        :custom-sort="customSort"
      >
       
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="props" v-if="$props.editMode !== 'read'">
          <v-btn
            class="mx-2"
            icon
            @click="deleteRegistrationHandler(props.item)"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.date_start="props">
         {{`${new Date(props.item.date_start).toLocaleDateString()} ${new Date(props.item.date_start).toLocaleTimeString()}`}}
        </template>
        
        <!-- eslint-disable-next-line -->
        <template v-slot:item.date_end="props">
         {{`${new Date(props.item.date_end).toLocaleDateString()} ${new Date(props.item.date_end).toLocaleTimeString()}`}}
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { projectEditorBus } from "../../../main";

export default {
  data: () => ({
    selectedRegistration: {},
    deleteRegistrationDialog: false,
  }),
  props: ["registrationData", "editMode"],
  created() {
    projectEditorBus.$on("changeDeleteRegistrationDialog", (val) => {
      this.deleteRegistrationDialog = val;
    });
  },
  computed: {
    tableHeaders() {
      if (this.$props.editMode === "read") {
        return [
          { text: "Name", value: "name" },
          { text: "E-Mail", value: "registration_email" },
          { text: "Tel", value: "registration_phone" },
          { text: "Schule", value: "registration_school" },
          { text: "Teilnehmer", value: "registration_attendants" },
          {
            text: "Start",
            value: "date_start",
          },
          {
            text: "Ende",
            value: "date_end",
          },
          { text: "Kommentar", value: "registration_comment" },
        ];
      } else {
        return [
          { text: "Name", value: "name" },
          { text: "E-Mail", value: "registration_email" },
          { text: "Tel", value: "registration_phone" },
          { text: "Schule", value: "registration_school" },
          { text: "Teilnehmer", value: "registration_attendants" },
          {
            text: "Start",
            value: "date_start",
          },
          {
            text: "Ende",
            value: "date_end",
          },
          { text: "Kommentar", value: "registration_comment" },
          { text: "Löschen", value: "delete", sortable: false },
        ];
      }
    },
    registrations() {
      return this.$props.registrationData.map((e) => {
        return {
          ...e,
          name: `${e.registration_firstname} ${e.registration_secondname}`,
          /*date_start: `${new Date(e.date_start)} ${new Date(e.date_start)}`,
          date_end: `${new Date(e.date_end)} ${new Date(e.date_end)}`,*/
        };
      });
    },
  },
  beforeDestroy() {
    projectEditorBus.$off("changeDeleteRegistrationDialog");
  },
  methods: {
    deleteRegistrationHandler(registration) {
      this.deleteRegistrationDialog = true;
      this.selectedRegistration = registration;
    },
    deleteRegistration() {
      projectEditorBus.$emit("deleteRegistration", this.selectedRegistration);
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "date_start" || index[0] == "date_end") {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },
};
</script>

<style>
</style>