<template>
  <v-app-bar app v-if="$vuetify.breakpoint.smAndDown">
    <v-app-bar-nav-icon @click="toggleNavDrawer"></v-app-bar-nav-icon>

    <v-toolbar-title>
      <v-img
        src="../../assets/images/logo_border.png"
        :max-width="smallMeasures.logoWidth"
        contain
      >
      </v-img>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { navBus } from "../../main";

export default {
  props: ['smallMeasures', 'bigMeasures'],
  data: () => ({
    drawer: null,
  }),
  methods: {
    toggleNavDrawer() {
      this.drawer ? this.drawer != this.drawer : (this.drawer = true);
      navBus.$emit("toggleNavDrawer", this.drawer);
    },
  },
};
</script>