<template>
  <v-menu
    v-model="opened"
    :close-on-content-click="false"
    :activator="selectedElement"
    offset-x
  >
    <v-card
      color="grey lighten-4"
      :min-width="$vuetify.breakpoint.xs ? '100%' : '400px'"
      max-width="600px"
      flat
    >
      <v-toolbar dark>
        <v-toolbar-title v-html="$props.selectedEvent.name"></v-toolbar-title>
      </v-toolbar>
      <v-form @submit.prevent="register()" ref="registerForm">
        <v-card-text class="text-body-1">
          <v-row v-if="$props.selectedEvent.date_free_spaces > 0">
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registration.firstName"
                label="Vorname"
                :rules="
                  getRules({ required: true, minString: 3, maxString: 255 })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registration.secondName"
                label="Nachname"
                :rules="
                  getRules({ required: true, minString: 3, maxString: 255 })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registration.school"
                label="Schule und Klasse"
                :rules="
                  getRules({ required: true, minString: 3, maxString: 255 })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registration.email"
                label="E-Mail"
                type="email"
                :rules="
                  getRules({ required: true, email: true, maxString: 255 })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registration.phone"
                label="Tel. Nummer"
                :rules="
                  getRules({ required: true, maxString: 255 })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="registration.attendants"
                label="Teilnehmer inkl. Begleitpersonen"
                type="number"
                :rules="
                  getRules({
                    required: true,
                    minNumber: 1,
                    maxAttendants: $props.selectedEvent.date_free_spaces,
                  })
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="registration.comment"
                label="Bemerkung"
                :rules="getRules({ maxString: 255 })"
              ></v-textarea>
            </v-col>
          </v-row>
          <p v-else class="red--text">Hier sind keine Plätze mehr frei</p>
          <p v-if="currentRegistrations[0] != null" class="red--text">
            Ihre Anmeldungen für diesen Termin:
          </p>
          <v-expansion-panels
            class="mt-1"
            flat
            v-if="currentRegistrations[0] != null"
          >
            <v-expansion-panel style="background: transparent">
              <v-expansion-panel-header class="ma-0 pa-0">
                Meine Anmeldungen ({{
                  currentRegistrations.length
                }})</v-expansion-panel-header
              >
              <v-expansion-panel-content class="ma-0 pa-0">
                <v-data-table
                  style="background: transparent"
                  hide-default-footer
                  disable-filtering
                  disable-pagination
                  disable-sort
                  :headers="[
                    { text: 'Name', value: 'name' },
                    { text: 'Schule', value: 'registration_school' },
                    { text: 'E-Mail', value: 'registration_email' },
                    { text: 'Tel', value: 'registration_phone' },
                    { text: 'Teilnehmer', value: 'registration_attendants' },
                    { text: 'Bemerkung', value: 'registration_comment' },

                    /* { text: 'Löschen', value: 'delete', sortable: false },*/
                  ]"
                  :items="currentRegistrations"
                >
                  <!-- eslint-disable-next-line -->
                  <!--  <template v-slot:item.delete="props">
                    <v-btn class="mx-2" icon :disabled="deletable">
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>-->
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="opened = false"> Schliessen </v-btn>
          <v-btn color="primary" type="submit"> Absenden </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-menu>
</template>

<script>
import { registerBus } from "../../../main";
const variables = require("../../../assets/js/variables");

export default {
  data: () => ({
    dialog: false,
    selectedDelete: "",
    editStartDate: false,
    editEndDate: false,
    editAttendants: false,
    registration: {
      firstName: "",
      secondName: "",
      email: "",
      phone:"",
      attendants: "",
      comment: "",
      school: "",
      voucherID: "",
      dateID: "",
    },
  }),

  props: ["selectedEvent", "selectedElement", "selectedOpen", "registrations"],
  created() {},
  methods: {
    getRules(rules) {
      return variables.getRules(rules);
    },
    register() {
      if (this.$refs.registerForm.validate()) {
        this.registration.voucherID = this.$route.params.voucher;
        this.registration.dateID = this.$props.selectedEvent.date_id;
        registerBus.$emit("register", this.registration);
      }
    },
    convertDateTime(dateTime) {
      if (dateTime) {
        let date = new Date(dateTime.replaceAll("-", "/"));
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`;
      }
    },
  },
  computed: {
    /* deletable() {
      let today = new Date();
      let date = new Date(this.$props.selectedEvent.start);
      let diffTime = Math.abs(today - date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 3 && today < date) {
        return false;
      }
      return true;
    },*/
    currentRegistrations() {
      let filtered = [];
      filtered = this.$props.registrations.filter((registration) => {
        return registration.date_id == this.$props.selectedEvent.date_id;
      });
      return filtered.map((e) => {
        if (e.date_id == this.$props.selectedEvent.date_id) {
          return {
            ...e,
            name: `${e.registration_firstname} ${e.registration_secondname}`,
          };
        }
      });
    },
    opened: {
      get() {
        return this.$props.selectedOpen;
      },
      set(newVal) {
        this.registration = {
          firstName: "",
          secondName: "",
          email: "",
          phone:"",
          attendants: "",
          comment: "",
          voucherID: "",
          dateID: "",
        };
        this.$refs.registerForm.resetValidation();
        registerBus.$emit("changeRegisterMenu", newVal);
      },
    },
    selectedStartLocal() {
      return this.convertDateTime(this.$props.selectedEvent.start);
    },
    selectedEndLocal() {
      return this.convertDateTime(this.$props.selectedEvent.end);
    },
  },
};
</script>

<style lang="scss">
</style>