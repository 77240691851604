<template>
  <v-row justify="center">
    <v-dialog v-model="openState" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h3 text-wrap">Projekt erstellen</span>
        </v-card-title>
        <v-card-text>
          <v-form
            @submit.prevent="createProject(projectName)"
            ref="createProjectForm"
          >
            <v-text-field
              v-model="projectName"
              label="Projektname"
              :rules="getRules({ required: true })"
            >
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="changeState()"> Abbrechen </v-btn>
          <v-btn color="primary" @click="createProject(projectName)">
            Erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
const variables = require("../../../assets/js/variables");

export default {
  data: () => ({
    openState: false,
    projectName: "",
  }),
  methods: {
    changeState: function () {
      this.projectName = "";
      this.openState = !this.openState;
    },
    createProject(projectName) {
      if (this.$refs.createProjectForm.validate()) {
        this.$parent.$emit("createProject", projectName);
      }
    },
    getRules(rules) {
      return variables.getRules(rules);
    },
  },
};
</script>

<style>
</style>