<template>
  <div id="adminDashboard">
    <v-dialog v-model="showDeleteProjectPrompt" max-width="290">
      <v-card>
        <v-card-title class="headline"> Projekt löschen? </v-card-title>
        <v-card-text>
          Das Projekt und alle dazugehörigen Termine, Vouchers und
          Registrationen werden gelöscht. Dies kann nicht rückgängig gemacht
          werden!</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showDeleteProjectPrompt = false"> Nein </v-btn>
          <v-btn color="primary" @click="deleteProject()"> Ja </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <p class="text-h2">Meine Projekte</p>
    <ShareDialog
      ref="shareDialog"
      :shares="shares"
      :showShareDialog="showShareDialog"
      :projectID="sharedProjectID"
    />
    <NewProjectDialog ref="newProjectDialog" />

    <div
      :class="`projects d-flex align-center flex-wrap mb-4 ${
        $vuetify.breakpoint.xs ? 'flex-column' : ''
      }`"
    >
      <ProjectCard
        v-for="ownProject in ownedProjects"
        :key="ownProject.project_id"
        :projectDetails="ownProject"
      />
      <v-btn icon height="150px" width="150px" @click="changeDialogState()">
        <v-icon size="150px">mdi-plus</v-icon>
      </v-btn>
    </div>
    <p class="text-h2">Mit mir geteilt</p>
    <div
      :class="`projects d-flex align-center flex-wrap mb-4 ${
        $vuetify.breakpoint.xs ? 'flex-column' : ''
      }`"
    >
      <ProjectCard
        v-for="sharedProject in sharedProjects"
        :key="sharedProject.project_id"
        :projectDetails="sharedProject"
      />
    </div>
  </div>
</template>

<script>
import ProjectCard from "../../components/Admin/Dashboard/ProjectCard";
import NewProjectDialog from "../../components/Admin/Dashboard/newProjectDialog";
import ShareDialog from "../../components/Admin/Dashboard/shareProjectDialog";
import api from "../../assets/js/api";
import { navBus, projectEditorBus } from "../../main";

export default {
  data: () => ({
    ownedProjects: [],
    sharedProjects: [],
    deleteProjectID: "",
    showDeleteProjectPrompt: false,
    showShareDialog: false,
    sharedProjectID: "",
    shares: [],
  }),
  components: { ProjectCard, NewProjectDialog, ShareDialog },
  methods: {
    getShares(projectID) {
      api.getShares(projectID).then((response) => {
        if (response.data.success) {
          this.shares = response.data.shares;
        }
      });
    },
    changeDialogState() {
      this.$refs.newProjectDialog.changeState();
    },
    deleteProject() {
      api.deleteProject(this.deleteProjectID).then((result) => {
        if (result.data.success) {
          this.ownedProjects = this.ownedProjects.filter(
            (project) => project.project_id !== this.deleteProjectID
          );
          this.showDeleteProjectPrompt = false;
        }
      });
    },
    createProject(projectName) {
      api.createProject(projectName).then((response) => {
        if (response.data.success) {
          this.openEditor(response.data.projectDetails.projectID, "owner");
        }
      });
    },
    openEditor(projectID,editMode) {
      this.$router.push({
        name: "ProjectEditor",
        params: { project_id: projectID },
      });
      projectEditorBus.$emit("changeNavigationTiles", "projectEditor");
      projectEditorBus.$emit("changeEditMode", editMode);
    },
  },
  created() {
    navBus.$emit("changeNavigationTiles", "main");
    api.getProjects().then((projects) => {
      let ownedProjects = projects.data.filter(
        (project) => project.user_permission === "owner"
      );

      let sharedProjects = projects.data.filter(
        (project) =>
          project.user_permission === "read" ||
          project.user_permission === "write"
      );

      this.ownedProjects = ownedProjects;
      this.sharedProjects = sharedProjects;
    });

    this.$on("deleteProject", (projectID) => {
      this.deleteProjectID = projectID;
      this.showDeleteProjectPrompt = true;
    });

    this.$on("createProject", (projectName) => {
      this.createProject(projectName);
    });

    this.$on("editProject", (editDetails) => {
      this.openEditor(editDetails.projectID, editDetails.permission);
    });

    this.$on("changeShareDialog", (val) => {
      if (val.show === true) {
        this.sharedProjectID = val.projectID;
        this.getShares(val.projectID);
      }
      this.showShareDialog = val.show;
    });

    this.$on("createShare", (project) => {
      api
        .createShare(project.projectID, project.email, project.permission)
        .then((response) => {
          if (response.data.success) {
            this.shares = response.data.shares;
            this.$refs.shareDialog.$emit("closeNewShareDialog");
          }
        })
        .catch((err) => {
          if (err.response.data.msg === "User not found") {
            this.$refs.shareDialog.$emit("userNotFound");
          } else if (err.response.data.msg === "Cannot share with this user") {
            this.$refs.shareDialog.$emit("illegalUser");
          }
        });
    });

    this.$on("deleteShare", (share) => {
      api
        .deleteShare(share.projectID, share.sharedUserEmail)
        .then((response) => {
          if (response.data.success) {
            this.shares = this.shares.filter(
              (filteredShare) =>
                filteredShare.user_email !== share.sharedUserEmail
            );
          }
        });
    });
  },
  beforeDestroy() {
    this.$off("deleteProject");
    this.$off("createProject");
    this.$off("editProject");
    this.$off("changeShareDialog");
    this.$off("createShare");
  },
};
</script>

<style>
</style>