<template>
  <div id="registrationsPanelCustomer">
    <v-dialog v-model="deleteRegistrationDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h3 text-wrap">Anmeldung löschen</span>
        </v-card-title>
        <v-card-text>
          Diese Anmeldung kann noch zurückgezogen werden. Fortfahren?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteRegistrationDialog = false">
            Abbrechen
          </v-btn>
          <v-btn color="primary" @click="deleteRegistration()">
            Fortfahren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <h2 class="text-h3">Meine Anmeldungen</h2>
    <div class="registrations">
      <v-data-table
        :sort-by="'date_start'"
        :sort-desc="true"
        v-if="registrations"
        :headers="tableHeaders"
        :items="registrations"
        hide-default-footer
        disable-filtering
        disable-pagination
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.delete="props">
          <v-tooltip top :disabled="!deletable(props.item.date_start)">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  class="mx-2"
                  icon
                  :disabled="deletable(props.item.date_start)"
                  @click="deleteRegistrationHandler(props.item)"
                >
                  <v-icon dark>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
            <span
              >Kann nur bis 3 Tage vor dem Termin gelöscht werden. Bitte
              benutzen Sie das Kontaktformular</span
            >
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { registerBus } from "../../../main";

export default {
  data: () => ({
    selectedRegistration: {},
    deleteRegistrationDialog: false,
    sortdesc: false,
  }),
  props: ["registrationData"],
  created() {
    registerBus.$on("changeDeleteRegistrationDialog", (val) => {
      this.deleteRegistrationDialog = val;
    });
  },
  computed: {
    tableHeaders() {
      return [
        { text: "Name", value: "name" },
        { text: "E-Mail", value: "registration_email" },
        { text: "Tel", value: "registration_phone" },
        { text: "Schule", value: "registration_school" },
        { text: "Teilnehmer", value: "registration_attendants" },
        { text: "Start", value: "date_start_formatted" },
        { text: "Ende", value: "date_end_formatted" },
        { text: "Kommentar", value: "registration_comment" },
        { text: "Löschen", value: "delete", sortable: false },
      ];
    },
    registrations() {
      return this.$props.registrationData.map((e) => {
        return {
          ...e,
          name: `${e.registration_firstname} ${e.registration_secondname}`,
          date_start_formatted: `${new Date(
            e.date_start
          ).toLocaleDateString()} ${new Date(
            e.date_start
          ).toLocaleTimeString()}`,
          date_end_formatted: `${new Date(
            e.date_end
          ).toLocaleDateString()} ${new Date(e.date_end).toLocaleTimeString()}`,
        };
      });
    },
  },
  beforeDestroy() {
    registerBus.$off("changeDeleteRegistrationDialog");
  },
  methods: {
    deletable(date) {
      date = new Date(date);
      let today = new Date();
      let diffTime = Math.abs(today - date);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 3 && today < date) {
        return false;
      }
      return true;
    },
    deleteRegistrationHandler(registration) {
      this.deleteRegistrationDialog = true;
      this.selectedRegistration = registration;
    },
    deleteRegistration() {
      registerBus.$emit("deleteRegistration", this.selectedRegistration);
    },
  },
};
</script>

<style>
</style>