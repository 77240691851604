import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

// Event bus
export const navBus = new Vue();
export const projectEditorBus = new Vue();
export const registerBus = new Vue();
export const apiBus = new Vue();

// Filters
Vue.filter('projectCardSnippet', (value) => {
  if (value.length >= 15) {
    value = `${value.slice(0, 17)}...`;
  }
  return value;
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
