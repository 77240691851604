<template>
  <div class="text-center">
    <v-dialog persistent v-model="opened" width="500">
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title>
            <template v-if="mode == 'create'">Neuer Eintrag</template>
            <template v-else>Eintrag bearbeiten</template>
          </v-toolbar-title>
        </v-toolbar>
        <v-form ref="newDateForm" @submit.prevent="createDate()">
          <v-card-text class="text-body-1">
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                  v-model="datePickerOpened"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selectedDateLocal"
                      label="Datum"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectedDate"
                    @input="datePickerOpened = false"
                    locale="de"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                  type="text"
                  label="Startzeit"
                  hint="Format hh:mm"
                  v-if="selectedDateLocal"
                  required
                  :rules="getRules({ required: true, time: true })"
                  v-model="startTime"
                  @blur="formatTime()"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                  type="text"
                  label="Endzeit"
                  hint="Format hh:mm"
                  v-if="selectedDateLocal"
                  required
                  :rules="getRules({ required: true, time: true })"
                  v-model="endTime"
                  @blur="formatTime()"
                  :error-messages="endTimeError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="
                    getRules({ required: true, minNumber: 1, maxNumber: 254 })
                  "
                  v-if="selectedDateLocal"
                  label="Anzahl Teilnehmer"
                  type="number"
                  v-model="attendants"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="secondary" @click="closeDialog()">
              Schliessen
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">Speichern</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { projectEditorBus } from "../../../main";
const variables = require("../../../assets/js/variables");

export default {
  data: () => ({
    datePickerOpened: false,
    selectedDate: null,
    attendants: null,
    startTime: null,
    endTime: null,
  }),
  props: ["showDialog", "mode", "editedEvent"],

  methods: {
    formatTime() {
      if (this.startTime) {
        this.startTime = this.startTime.trim();
        this.startTime = this.startTime.replaceAll(".", ":");
        this.startTime = this.startTime.replaceAll("-", ":");
        this.startTime = this.startTime.replaceAll(" ", ":");
        if (this.startTime.split(":")[0].length === 1) {
          this.startTime = `0${this.startTime}`;
        }
      }
      if (this.endTime) {
        this.endTime = this.endTime.trim();
        this.endTime = this.endTime.replaceAll(".", ":");
        this.endTime = this.endTime.replaceAll("-", ":");
        this.endTime = this.endTime.replaceAll(" ", ":");
        if (this.endTime.split(":")[0].length === 1) {
          this.endTime = `0${this.endTime}`;
        }
      }
    },
    getRules(rules) {
      return variables.getRules(rules);
    },
    closeDialog() {
      projectEditorBus.$emit("changeNewDateDialog", {
        mode: "create",
        state: false,
      });
    },
    createDate() {
      if (
        this.$refs.newDateForm.validate() &&
        `${this.endTime}:00` > `${this.startTime}:00`
      ) {
        let projectID = this.$route.params.project_id;
        let dateID = this.editedEvent.date_id;
        let date = this.selectedDate;
        let start = this.startTime;
        let end = this.endTime;
        let attendants = this.attendants;

        let startDate = new Date(`${date} ${start}`).toISOString();
        let endDate = new Date(`${date} ${end}`).toISOString();

        if (this.mode == "create") {
          projectEditorBus.$emit("createDate", {
            projectID: projectID,
            startDate: startDate,
            endDate: endDate,
            attendants: attendants,
          });
        } else if (this.mode == "edit") {
          projectEditorBus.$emit("editDate", {
            dateID: dateID,
            start: startDate,
            end: endDate,
            attendants: attendants,
          });
        }
      }
    },
  },
  computed: {
    endTimeError() {
      if (
        `${this.endTime}:00` > `${this.startTime}:00` ||
        this.endTime == null
      ) {
        return null;
      } else {
        return "Muss grösser sein als Startzeit";
      }
    },
    selectedDateLocal: {
      get() {
        if (this.selectedDate == null) {
          return null;
        }
        try {
          let date = new Date(this.selectedDate);
          return date.toLocaleDateString();
        } catch {
          return null;
        }
      },
      set() {},
    },
    opened: {
      get() {
        return this.showDialog;
      },
      set() {
        this.closeDialog();
      },
    },
  },
  created() {
    projectEditorBus.$on("closeDialog", () => {
      this.closeDialog();
    });

    projectEditorBus.$on("changeNewDateDialog", (values) => {
      if (values.state == false) {
        this.selectedDate = null;
        this.attendants = null;
        this.startTime = null;
        this.endTime = null;
      } else {
        if (values.mode == "edit" && this.editedEvent) {
          this.selectedDate = this.editedEvent.start.split(" ")[0];
          // .replaceAll("-", "/");
          this.startTime = this.editedEvent.start.split(" ")[1];
          this.endTime = this.editedEvent.end.split(" ")[1];
          this.attendants = this.editedEvent.date_all_spaces;
        }
      }
    });
  },
};
</script>

<style>
</style>