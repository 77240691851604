<template>
  <div>
    <router-view />
  </div>
</template>

<style lang="scss">
.v-card__text,
.v-card__title {
  & > * {
    word-break: normal !important;
  }
}
</style>

<script>
export default {
  components: {},

  data: () => ({}),
};
</script>
