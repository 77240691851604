<template>
  <div class="calendar">
    <p class="text-h3">Terminkalender</p>
    <NewDateMenu
      :showDialog="newDateDialogShow"
      :mode="newDateMode"
      :editedEvent="selectedEvent"
    />
    <v-row class="fill-height">
      <v-col>
        <v-sheet>
          <v-toolbar flat :height="calendarToolbarHeight">
            <div class="d-flex flex-row flex-wrap my-auto">
              <v-btn
                v-if="$props.editMode !== 'read'"
                class="mr-4"
                color="primary"
                @click="
                  newDateMode = 'create';
                  newDateDialogShow = true;
                "
              >
                Neuer Eintrag
              </v-btn>

              <v-btn outlined color="grey darken-2" @click="setToday"
                >Heute</v-btn
              >
              <div>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <v-icon small> mdi-chevron-left </v-icon>
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="next"
                  class="mr-1"
                >
                  <v-icon small> mdi-chevron-right </v-icon>
                </v-btn>
              </div>
              <v-toolbar-title v-if="$refs.calendar" class="mr-5">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Tag</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Woche</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Monat</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 Tage</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="500">
          <v-calendar
            interval-height="30"
            :locale="locale"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :type="type"
            :short-months="false"
            :short-weekdays="false"
            :start="today"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
          <EditDateMenu
            :selectedOpen="selectedOpen"
            :currentRegistrations="currentRegistrations"
            :selectedEvent="selectedEvent"
            :selectedElement="selectedElement"
            :editMode="$props.editMode"
          />
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EditDateMenu from "./EditDateMenu";
import NewDateMenu from "./NewDateMenu";
import { projectEditorBus } from "../../../main";

export default {
  props: ["mode", "dates", "registrations", "editMode"],
  components: { EditDateMenu, NewDateMenu },
  data: () => ({
    focus: null,
    type: "week",
    typeToLabel: {
      month: "Monat",
      week: "Woche",
      day: "Tag",
      "4day": "4 Tage",
    },
    name: null,
    today: new Date(),
    details: null,
    start: null,
    end: null,
    color: null,
    currentlyEditing: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    newDateDialogShow: false,
    newDateMode: "create",
    screenWidth: null,
  }),
  computed: {
    events() {
      let events = this.$props.dates;

      events = events.map(({ date_start: start, date_end: end, ...rest }) => ({
        start,
        end,
        ...rest,
      }));

      events = events.map((e) => {
        return {
          ...e,
          start: this.formatDate(e.start),
          end: this.formatDate(e.end),
          name: `${e.date_free_spaces.toString()} / ${e.date_all_spaces.toString()} Plätze frei`,
        };
      });
      return events;
    },
    currentRegistrations() {
      if (this.registrations != null) {
        let registrations = this.registrations.filter(
          (registration) => registration.date_id === this.selectedEvent.date_id
        );
        registrations = registrations.map((registration) => ({
          attendants: registration.registration_attendants,
          comment: registration.registration_comment,
          email: registration.registration_email,
          name: `${registration.registration_firstname} ${registration.registration_secondname}`,
        }));
        return registrations;
      } else {
        return null;
      }
    },
    calendarToolbarHeight() {
      if (this.screenWidth < 350) {
        return "130px";
      } else if (this.screenWidth < 400) {
        return "110px";
      } else if (this.screenWidth < 650) {
        return "100px";
      } else {
        return "50px";
      }
    },
    locale() {
      return Intl.DateTimeFormat().resolvedOptions().locale;
    },
  },
  mounted() {
    this.$refs.calendar.move(0);
    this.$refs.calendar.scrollToTime("08:00");
  },
  created() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.screen.width;
    });

    if (this.$vuetify.breakpoint.xs) {
      this.type = "day";
    }

    projectEditorBus.$on("changeNewDateDialog", (values) => {
      this.newDateMode = values.mode;
      this.newDateDialogShow = values.state;
    });

    projectEditorBus.$on("changeEditDateDialog", (value) => {
      this.selectedOpen = value;
    });
  },
  destroyed() {
    window.removeEventListener("resize", () => {
      this.screenWidth = window.screen.width;
    });
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    formatDate(date) {
      date = new Date(date);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${(
        "0" + date.getHours()
      ).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
    },
  },
};
</script>