<template>
  <v-app-bar app>
    <v-toolbar-title>{{ projectName }}</v-toolbar-title>
  </v-app-bar>
</template>
<script>
export default {
  props: ["project_name"],
  computed: {
    projectName() {
      return this.$props.project_name;
    },
  },
};
</script>

<style>
</style>