<template>
  <v-dialog v-model="$props.accessDeniedDialog" max-width="290">
    <v-card>
      <v-card-title class="headline">Zugriff nicht möglich</v-card-title>
      <v-card-text>Dieses Projekt existiert nicht mehr.</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="toHome()">Dashboard</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["accessDeniedDialog"],
  methods: {
    toHome() {
      this.$emit("close");
      this.$router.push("/admin");
    },
  },
};
</script>

<style>
</style>