// Not exported
/*const rules = {
    "required": ((v) => !!v || "Dieses Feld ist erforderlich"),
    "time": (v) => /\b(?:(?:0){0,1}[1-9]|[1-1][0-9]|[2-2][0-4]):(?:(?:0){1}[0-9]|[1-4][0-9]|[5-5][0-9])(?:\s)*\b/.test(v) || "Bitte im Format hh:mm",
    "number255": (v) => /\b(0*(?:[1-9][0-9]?|254))\b/.test(v) || "Nur Nummern zwischen 1 und 254",
    "text50": (v) => (v.length >= 3 && v.length <= 50) || "Zwischen 3 und 50 Zeichen",
    "email": (v) => /.+@.+\..+/.test(v) || "Bitte eine gültige E-Mail Adresse verwenden",
    "text255": (v) => (v.length <= 254) || "Maximal 254 Zeichen",
}*/

// Exported
function getRules(selectedRules) {
    let ruleSet = [];

    Object.keys(selectedRules).forEach((rule) => {
        switch (rule) {
            case "minString":
                ruleSet.push((v) => (v.length >= selectedRules[rule]) || `Mindestens ${selectedRules[rule]} Zeichen`);
                break;
            case "maxString":
                ruleSet.push((v) => (v.length <= selectedRules[rule]) || `Maximal ${selectedRules[rule]} Zeichen`);
                break;
            case "maxNumber":
                ruleSet.push((v) => (parseInt(v) <= selectedRules[rule]) || `Zahl muss kleiner / gleich gross sein als ${selectedRules[rule]}`);
                break;
            case "minNumber":
                ruleSet.push((v) => (parseInt(v) >= selectedRules[rule]) || `Zahl muss grösser / gleich gross sein als ${selectedRules[rule]}`);
                break;
            case "maxAttendants":
                ruleSet.push((v) => (parseInt(v) <= selectedRules[rule]) || `Es sind nur noch ${selectedRules[rule]} Plätze frei`);
                break;
            case "required":
                if (selectedRules[rule] === true) { ruleSet.push((v) => !!v || "Dieses Feld ist erforderlich"); }
                break;
            case "time":
                if (selectedRules[rule] === true) {
                    ruleSet.push((v) => /\b(?:(?:0){0,1}[1-9]|[1-1][0-9]|[2-2][0-4]):(?:(?:0){1}[0-9]|[1-4][0-9]|[5-5][0-9])(?:\s)*\b/.test(v) || "Bitte im Format hh:mm");
                }
                break;
            case "email":
                if (selectedRules[rule] === true) {
                    ruleSet.push((v) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || "Bitte eine gültige E-Mail Adresse verwenden");
                }
                break;
        }
    });

    return ruleSet;
}

module.exports.getRules = getRules