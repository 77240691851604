<template>
  <v-app>
    <AppBar :project_name="projectName" />
    <v-main>
      <v-container class="registration">
        <span class="text-subtitle-1">
          Grüezi<br />Hier können Sie Ihre Klasse für das Aarauer Kerzenziehen
          anmelden. Füllen Sie dazu bitte das beim Anwählen eines Feldes
          erscheinende Formular aus.<br />Anmeldungen können bis drei Tage vor
          Termin gelöscht werden.<br />
          Änderungen bitte per
          <a @click.prevent="scrollDown()">Kontaktformular</a> melden.
          <br />Besten Dank und herzliche Grüsse!<br />Das Kerzenziehteam
          <br/><b>Besten Dank, dass Sie sich an Ihre gebuchten Zeiten halten.</b>
        </span>

        <br />

        <Calendar
          :calendarDisabled="remainingSubmissions > 0"
          :dates="events"
          :registrationData="registrationData"
          v-if="events"
        />
        <Registrations
          v-if="registrationData"
          :registrationData="registrationData"
        />
        <ContactForm class="mt-5" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import api from "../../assets/js/api";
import AppBar from "../../components/User/Registration/AppBar";
import Calendar from "../../components/User/Registration/Calendar";
import ContactForm from "../../components/User/Registration/ContactForm";
import { registerBus } from "../../main";
import Registrations from "../../components/User/Registration/Registrations";

export default {
  data: () => ({
    events: [],
    remainingSubmissions: 0,
    projectName: "",
    registrationData: [],
  }),
  created() {
    api.getDates(this.$route.params.voucher).then((dates) => {
      this.events = dates.data;
      registerBus.$emit("loadedDates");
    });

    api.getVoucherDetails(this.$route.params.voucher).then((details) => {
      this.remainingSubmissions = details.data[0].voucher_remaining_submissions;
    });

    api
      .getProjectName("voucher", this.$route.params.voucher)
      .then((response) => {
        this.projectName = response.data[0].project_name;
      });

    api
      .getCustomerRegistrations(this.$route.params.voucher)
      .then((response) => {
        if (response.data.success) {
          this.registrationData = response.data.registrations;
        }
      });

    registerBus.$on("register", (registration) => {
      this.register(registration);
    });

    registerBus.$on("deleteRegistration", (registration) => {
      this.deleteRegistration(registration);
    });

    registerBus.$on("submitContactForm", (formData) => {
      api.submitCustomerForm(formData).then((response) => {
        if (response.data.success) {
          registerBus.$emit("contactFormSubmitted");
        }
      });
    });
  },
  beforeDestroy() {
    registerBus.$off("register");
    registerBus.$off("deleteRegistration");
    registerBus.$off("submitContactForm");
  },
  components: { AppBar, Calendar, ContactForm, Registrations },
  methods: {
    deleteRegistration(registration) {
      api
        .deleteRegistrationUser(registration.registration_id)
        .then((response) => {
          if (response.data.success) {
            this.registrationData = this.registrationData.filter(
              (reg) => reg.registration_id != registration.registration_id
            );
            registerBus.$emit("changeDeleteRegistrationDialog", false);
          }
        });
    },
    register(registration) {
      api
        .register(
          registration.voucherID,
          registration.dateID,
          registration.firstName,
          registration.secondName,
          registration.email,
          registration.phone,
          registration.attendants,
          registration.comment,
          registration.school
        )
        .then((response) => {
          if (response.data.success) {
            this.events.forEach((obj) => {
              if (obj.date_id == registration.dateID) {
                obj.date_free_spaces -= registration.attendants;
              }
            });
            let registeredDate = this.events.filter(
              (event) => event.date_id == registration.dateID
            )[0];
            registeredDate["registration_attendants"] = registration.attendants;
            registeredDate["registration_comment"] = registration.comment;
            registeredDate["registration_email"] = registration.email;
            registeredDate["registration_firstname"] = registration.firstName;
            registeredDate["registration_secondname"] = registration.secondName;
            registeredDate["registration_id"] = response.data.registrationID;
            registeredDate["registration_school"] = registration.school;
            this.registrationData.push(
              JSON.parse(JSON.stringify(registeredDate))
            );
            registerBus.$emit("changeRegisterMenu", false);
          }
        });
    },
    scrollDown() {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.registration {
  max-width: 1200px;
}
</style>