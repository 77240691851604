<template>
  <v-menu
    v-model="opened"
    :close-on-content-click="false"
    :activator="selectedElement"
    offset-x
  >
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Event löschen? </v-card-title>
        <v-card-text>
          Sollen dieser Event und alle damit verbundenen Registrationen gelöscht
          werden? Dieser Schritt kann nicht rückgängig gemacht
          werden.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false"> Nein </v-btn>
          <v-btn text @click="deleteDate()"> Ja </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card
      color="grey lighten-4"
      :min-width="$vuetify.breakpoint.xs ? '100%' : '400px'"
      max-width="600px"
      flat
    >
      <v-toolbar dark>
        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon @click="confirmDeleteDate(selectedEvent.date_id)" v-if="$props.editMode !== 'read'">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-body-1">
        <v-row class="ma-0" v-if="$vuetify.breakpoint.smAndUp">
          <v-col cols="6">
            <v-row>Startdatum:</v-row>
            <v-row>Enddatum:</v-row>
            <v-row>Anzahl Plätze total:</v-row>
            <v-row>Anzahl Plätze frei:</v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <div>
                {{ selectedStartLocal }}
              </div>
            </v-row>
            <v-row>
              <div>
                {{ selectedEndLocal }}
              </div>
            </v-row>
            <v-row>
              <div>
                {{ selectedEvent.date_all_spaces }}
              </div>
            </v-row>
            <v-row>{{ selectedEvent.date_free_spaces }}</v-row>
          </v-col>
        </v-row>

        <v-col v-else>
          <v-row class="text-subtitle-2">Startdatum:</v-row>
          <v-row>{{ selectedEvent.start }} </v-row>
          <v-row class="text-subtitle-2">Enddatum:</v-row>
          <v-row>{{ selectedEvent.end }} </v-row>
          <v-row class="text-subtitle-2">Anzahl Plätze total:</v-row>
          <v-row>{{ selectedEvent.date_all_spaces }} </v-row>
          <v-row class="text-subtitle-2">Anzahl Plätze frei:</v-row>
          <v-row>{{ selectedEvent.date_free_spaces }}</v-row>
        </v-col>
        <v-divider></v-divider>
        <v-expansion-panels class="mt-1" flat>
          <v-expansion-panel style="background: transparent">
            <v-expansion-panel-header class="ma-0 pa-0">
              Anmeldungen ({{
                currentRegistrations.length
              }})</v-expansion-panel-header
            >
            <v-expansion-panel-content class="ma-0 pa-0">
              <v-data-table
                style="background: transparent"
                hide-default-footer
                disable-filtering
                disable-pagination
                disable-sort
                :headers="[
                  { text: 'Name', value: 'name' },
                  { text: 'E-Mail', value: 'email' },
                  { text: 'Teilnehmer', value: 'attendants' },
                  { text: 'Bemerkung', value: 'comment' },
                ]"
                :items="currentRegistrations"
              ></v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="changeDialog(false)"> Schliessen </v-btn>
        <v-btn color="primary" @click="editDate()"  v-if="$props.editMode !== 'read'"> Bearbeiten </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { projectEditorBus } from "../../../main";

export default {
  data: () => ({
    dialog: false,
    selectedDelete: "",
    editStartDate: false,
    editEndDate: false,
    editAttendants: false,
  }),

  props: [
    "selectedEvent",
    "selectedElement",
    "currentRegistrations",
    "selectedOpen",
    "editMode"
  ],

  methods: {
    getRegistrations(dateID) {
      return this.$parent.getRegistrations(dateID);
    },
    changeDialog(newVal) {
      projectEditorBus.$emit("changeEditDateDialog", newVal);
    },
    confirmDeleteDate(dateID) {
      this.selectedDelete = dateID;
      this.dialog = true;
    },
    deleteDate() {
      projectEditorBus.$emit("deleteDate", this.selectedDelete);
    },
    convertDateTime(dateTime) {
      if (dateTime) {
        let date = new Date(dateTime.replaceAll("-", "/"));
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`;
      }
    },
    editDate() {
      projectEditorBus.$emit("changeNewDateDialog", {
        mode: "edit",
        state: true,
      });
    },
  },
  computed: {
    opened: {
      get() {
        return this.selectedOpen;
      },
      set(newVal) {
        this.changeDialog(newVal);
      },
    },
    selectedStartLocal() {
      return this.convertDateTime(this.selectedEvent.start);
    },
    selectedEndLocal() {
      return this.convertDateTime(this.selectedEvent.end);
    },
  },
};
</script>

<style lang="scss">
  
</style>