<template>
  <v-app>
    <AppBar
      :bigMeasures="{ logoWidth: '230px', barHeight: '80px' }"
      :smallMeasures="{ logoWidth: '180px', barHeight: '56px' }"
    />
    <NavDrawer
      :bigMeasures="{ logoWidth: '230px', barHeight: '80px' }"
      :smallMeasures="{ logoWidth: '180px', barHeight: '56px' }"
      :editMode="editMode"
      :userDetails="userDetails"
    />
    <v-main>
      <v-container>
        <v-dialog
          transition="dialog-top-transition"
          v-model="loginDialog"
          persistent
          width="500px"
        >
          <Login :embedded="true" />
        </v-dialog>
        <AccessDeniedMenu
          :accessDeniedDialog="accessDeniedDialog"
          @close="accessDeniedDialog = false"
        />
        <router-view :editMode="editMode" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./AppBar";
import NavDrawer from "./NavDrawer";
import Login from "../User/Auth/Login";
import { apiBus, projectEditorBus } from "../../main";
import AccessDeniedMenu from "../../components/Admin/ProjectEditor/AccessDeniedMenu";
import api from "../../assets/js/api";

export default {
  data: () => ({
    loginDialog: false,
    accessDeniedDialog: false,
    editMode: null,
    userDetails: {},
  }),
  components: { AppBar, NavDrawer, Login, AccessDeniedMenu },
  created() {
    projectEditorBus.$on("changeEditMode", (mode) => {
      this.editMode = mode;
    });

    apiBus.$on("showLoginDialog", () => {
      this.loginDialog = true;
    });

    apiBus.$on("showAccessDeniedDialog", () => {
      this.accessDeniedDialog = true;
    });
  },
  mounted() {
    api.getUserDetails().then((response) => {
      this.userDetails = response.data;
    });
  },
  methods: {
    showLoginDialog() {
      this.loginDialog = true;
    },
  },
};
</script>

<style>
</style>