<template>
  <v-dialog v-model="showDialog" max-width="700px">
    <v-dialog v-model="showNewShareDialog" max-width="290px">
      <v-card>
        <v-card-title>Neue Freigabe</v-card-title>
        <v-form @submit.prevent="createShare()" ref="newShareForm">
          <v-card-text>
            <v-text-field
            :error-messages="sharedUserErrorMsg"
              @keydown="
                illegalUser = false;
                userNotFound = false;
              "
              type="email"
              label="E-Mail Adresse"
              v-model="shareEmail"
              :rules="getRules({ required: true, email: true })"
            ></v-text-field>
            <v-select
              v-model="sharePermission"
              :items="[
                { text: 'Lesen', permission: 'read' },
                { text: 'Schreiben', permission: 'write' },
              ]"
              label="Berechtigung"
              item-text="text"
              item-value="permission"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="showNewShareDialog = false">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" type="submit">Freigeben</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>Projekt zum Bearbeiten freigeben</v-card-title>
      <v-card-text>
        <p class="text-h-4">Geteilt mit:</p>
        <v-data-table
          :headers="tableHeaders"
          :items="$props.shares"
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
        >
          <!-- eslint-disable-next-line -->
          <template v-slot:item.delete="props">
            <v-btn @click="deleteShareHandler(props.item)" class="mx-2" icon>
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-btn icon @click="createShareHandler()"
          ><v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showDialog = false">Schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const variables = require("../../../assets/js/variables");

export default {
  data: () => ({
    tableHeaders: [
      { text: "E-Mail:", value: "user_email" },
      { text: "Berechtigung:", value: "collaboration_permission" },
      { text: "Löschen:", value: "delete", sortable: false, width: "50px" },
    ],
    showNewShareDialog: false,
    shareEmail: "",
    sharePermission: "read",
    userNotFound: false,
    illegalUser: false,
  }),
  props: ["showShareDialog", "shares", "projectID"],
  created() {
    this.$on("closeNewShareDialog", () => {
      this.showNewShareDialog = false;
    });

    this.$on("userNotFound", () => {
      this.userNotFound = true;
    });

    this.$on("illegalUser", () => {
      this.illegalUser = true;
    });
  },
  computed: {
    showDialog: {
      get() {
        return this.$props.showShareDialog;
      },
      set(newVal) {
        this.$parent.$emit("changeShareDialog", { show: newVal });
      },
    },
    sharedUserErrorMsg(){
      if(this.userNotFound){
        return "Kein Benutzer unter dieser E-Mail Adresse gefunden"
      }
      else if(this.illegalUser){
        return "Kann nicht für diesen Benutzer freigegeben werden"
      }
      else {
        return null;
      }
    }
  },
  methods: {
    createShareHandler() {
      if (this.$refs.newShareForm) {
        this.$refs.newShareForm.resetValidation();
      }
      this.shareEmail = "";
      this.sharePermission = "read";
      this.showNewShareDialog = true;
    },
    deleteShareHandler(share) {
      this.$parent.$emit("deleteShare", {
        projectID: share.project_id,
        sharedUserEmail: share.user_email,
      });
    },
    getRules(rules) {
      return variables.getRules(rules);
    },
    createShare() {
      if (this.$refs.newShareForm.validate()) {
        this.$parent.$emit("createShare", {
          projectID: this.$props.projectID,
          email: this.shareEmail,
          permission: this.sharePermission,
        });
      }
    },
  },
};
</script>

<style>
</style>