const Axios = require('axios');
const apiEndpoint = 'https://surveyscope.net/api';
import { apiBus } from "../../main";

export default {
    getProjects: () => {
        if (window.location.href === "http://localhost:8080/admin/dashboard") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\": [{\"project_id\":\"401905e2-2574-11eb-b4a4-86ebf4b7f864\",\"project_name\":\"wird shared\",\"user_id\":\"fb2f359d-4588-4b65-9f31-ae5edd655c07\",\"user_permission\":\"read\",\"creation_time\":\"2020-11-13T05:51:18.714Z\",\"counted_dates\":4,\"counted_registrations\":3,\"counted_vouchers\":1,\"counted_collaborations\":1},{\"project_id\":\"1420b2ad-48cf-4ff9-b1c7-ee8f98868409\",\"project_name\":\"Projekt zum Testen vom Kalender mit langem TItel\",\"user_id\":\"fb2f359d-4588-4b65-9f31-ae5edd655c07\",\"user_permission\":\"owner\",\"creation_time\":\"2020-12-21T05:42:58.519Z\",\"counted_dates\":1,\"counted_registrations\":1,\"counted_vouchers\":2,\"counted_collaborations\":0},{\"project_id\":\"abf96707-35da-4f75-ae5e-a5f1a0509bcb\",\"project_name\":\"ddf\",\"user_id\":\"fb2f359d-4588-4b65-9f31-ae5edd655c07\",\"user_permission\":\"owner\",\"creation_time\":\"2020-12-19T12:07:20.350Z\",\"counted_dates\":1,\"counted_registrations\":0,\"counted_vouchers\":0,\"counted_collaborations\":0}]\n}'))
        }
        return Axios.post(`${apiEndpoint}/admin/getProjects`).catch((error) => { triggerLogin(error); });
    },


    createProject: (projectName) => {
        return Axios.post(`${apiEndpoint}/admin/createProject`, { projectName: projectName }).catch((error) => { triggerLogin(error); });
    },

    deleteProject: (projectID) => {
        return Axios.post(`${apiEndpoint}/admin/deleteProject`, { projectID: projectID }).catch((error) => { triggerLogin(error); });
    },

    getProjectName: (method, ID) => {

        if (window.location.href.indexOf("http://localhost:8080") != -1) {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\": [{\"project_name\":\"Zum testen projekt\"}]\n}'))
        }
        else {
            if (method === "voucher") {
                return Axios.post(`${apiEndpoint}/customer/getProjectName`, { voucherID: ID });
            }
            else {
                return Axios.post(`${apiEndpoint}/customer/getProjectName`, { projectID: ID }).catch((error) => { triggerLogin(error); });
            }
        }
    },

    getProjectDates: (projectID) => {
        if (window.location.href.indexOf("http://localhost:8080") != "-1") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\": [{\"date_id\":\"9b2c70a8-4218-11eb-a950-86ebf4b7f864\",\"project_id\":\"abf96707-35da-4f75-ae5e-a5f1a0509bcb\",\"date_start\":\"2021-03-19T16:37:53.000Z\",\"date_end\":\"2021-03-19T18:37:53.208Z\",\"date_all_spaces\":50,\"date_used_spaces\":0,\"date_free_spaces\":50}]\n}'))
        }
        return Axios.post(`${apiEndpoint}/admin/getDates`, { projectID: projectID }).catch((error) => { triggerLogin(error); });
    },

    getProjectRegistrations: (projectID) => {
        if (window.location.href.indexOf("http://localhost:8080") != "-1") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\": [{\"project_id\":\"1420b2ad-48cf-4ff9-b1c7-ee8f98868409\",\"date_start\":\"2021-01-05T07:01:00.000Z\",\"date_end\":\"2021-01-05T10:00:00.000Z\",\"registration_id\":\"463c7047-4de4-11eb-a950-86ebf4b7f864\",\"date_id\":\"c15acad1-0f20-44e5-9ed3-d25a0cf2e220\",\"voucher_id\":\"9d02bc77-434f-11eb-a950-86ebf4b7f864\",\"registration_firstname\":\"Hans\",\"registration_secondname\":\"Nötig\",\"registration_email\":\"hansnötig@gmail.com\",\"registration_attendants\":10,\"registration_comment\":\"Some comment which nobody is gonna read fml, i gotta write something long to test if it still looks fine on the webinterface. XSS injection test <script> alert(1)</script>\"}]\n}'))
        }
        return Axios.post(`${apiEndpoint}/admin/getRegistrations`, { projectID: projectID }).catch((error) => { triggerLogin(error); });
    },

    getProjectVouchers: (projectID) => {
        if (window.location.href.indexOf("http://localhost:8080") != "-1") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\": [{\"project_id\":\"1420b2ad-48cf-4ff9-b1c7-ee8f98868409\",\"voucher_id\":\"9d02bc77-434f-11eb-a950-86ebf4b7f864\",\"voucher_comment\":\"Testvoucher 1\",\"voucher_all_submissions\":10,\"voucher_used_submissions\":1,\"voucher_remaining_submissions\":9},{\"project_id\":\"1420b2ad-48cf-4ff9-b1c7-ee8f98868409\",\"voucher_id\":\"b55486f9-434f-11eb-a950-86ebf4b7f864\",\"voucher_comment\":\"Testvoucher 2\",\"voucher_all_submissions\":20,\"voucher_used_submissions\":0,\"voucher_remaining_submissions\":20}]\n}'))
        }
        return Axios.post(`${apiEndpoint}/admin/getVouchers`, { projectID: projectID }).catch((error) => { triggerLogin(error); });
    },

    createDate: (projectID, start, end, attendants) => {
        return Axios.post(`${apiEndpoint}/admin/createDate`, { projectID: projectID, start: start, end: end, attendants: attendants }).catch((error) => { triggerLogin(error); });
    },

    deleteDate: (dateID) => {
        return Axios.post(`${apiEndpoint}/admin/deleteDate`, { dateID: dateID }).catch((error) => { triggerLogin(error); });
    },

    updateDate: (dateID, start, end, attendants) => {
        return Axios.post(`${apiEndpoint}/admin/updateDate`, { dateID: dateID, start: start, end: end, attendants: attendants }).catch((error) => { triggerLogin(error); });
    },

    updateProject: (projectID, projectName) => {
        return Axios.post(`${apiEndpoint}/admin/updateProject`, { projectID: projectID, projectName: projectName }).catch((error) => { triggerLogin(error); });
    },

    deleteVoucher: (voucher) => {
        return Axios.post(`${apiEndpoint}/admin/deleteVoucher`, { voucherID: voucher }).catch((error) => { triggerLogin(error); });
    },

    createVoucher: (projectID, comment, submissions) => {
        return Axios.post(`${apiEndpoint}/admin/createVoucher`, { projectID: projectID, voucher_comment: comment, submissions: submissions }).catch((error) => { triggerLogin(error); });
    },

    updateVoucher: (voucherID, comment, submissions) => {
        return Axios.post(`${apiEndpoint}/admin/updateVoucher`, { voucherID: voucherID, voucher_comment: comment, submissions: submissions }).catch((error) => { triggerLogin(error); });
    },

    deleteRegistration: (registrationID) => {
        return Axios.post(`${apiEndpoint}/admin/deleteRegistration`, { registrationID: registrationID }).catch((error) => { triggerLogin(error); });
    },

    deleteRegistrationUser: (registrationID) => {
        return Axios.post(`${apiEndpoint}/customer/deleteRegistration`, { registrationID: registrationID });
    },

    getDates: (voucher) => {
        if (window.location.href.indexOf("http://localhost:8080") != "-1") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\": [{\"date_id\":\"1c63cf47-1d8c-465d-b5be-7e45d89c2240\",\"project_id\":\"0863b881-520e-4bb8-9205-d6c15fabb33d\",\"date_start\":\"2021-01-28T10:00:00.000Z\",\"date_end\":\"2021-01-28T15:00:00.000Z\",\"date_all_spaces\":20,\"date_used_spaces\":18,\"date_free_spaces\":2},{\"date_id\":\"a9cb0c8e-cedf-4c73-9edd-8724acce2665\",\"project_id\":\"0863b881-520e-4bb8-9205-d6c15fabb33d\",\"date_start\":\"2021-01-29T09:00:00.000Z\",\"date_end\":\"2021-01-29T10:00:00.000Z\",\"date_all_spaces\":50,\"date_used_spaces\":1,\"date_free_spaces\":49}]\n}'))
        }
        return Axios.post(`${apiEndpoint}/customer/getDates`, { voucherID: voucher });
    },

    register: (voucher, dateID, firstName, secondName, email,phone, attendants, comment, school) => {
        return Axios.post(`${apiEndpoint}/customer/register`, { voucherID: voucher, dateID: dateID, firstName: firstName, secondName: secondName, email: email, phone:phone,attendants: attendants, comment: comment, school:school });
    },

    getVoucherDetails: (voucher) => {
        if (window.location.href.indexOf("http://localhost:8080") != "-1") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\": [{"voucher_remaining_submissions":98}]\n}'))
        }
        return Axios.post(`${apiEndpoint}/customer/getVoucherDetails`, { voucherID: voucher });
    },

    login: (email, password) => {
        return Axios.post(`${apiEndpoint}/auth/login`, { email: email, password: password });
    },

    signup: (firstName, secondName, email, password) => {
        return Axios.post(`${apiEndpoint}/auth/register`, { firstName: firstName, secondName: secondName, email: email, password: password });
    },

    logout: () => {
        return Axios.post(`${apiEndpoint}/auth/logout`);
    },

    getShares: (projectID) => {
        if (window.location.href.indexOf("http://localhost:8080") != "-1") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\": {\"success\":true,\"shares\":[{\"id\":\"a68df7ec-cefc-47fd-93d2-a6037875177e\",\"project_id\":\"f597a608-ae1b-4a6a-a0e4-9f9d46d162d0\",\"collaboration_permission\":\"write\",\"user_email\":\"g@g.de\"}]}\n}'))
        }
        return Axios.post(`${apiEndpoint}/admin/getShares`, { projectID: projectID });
    },

    createShare: (projectID, email, permission) => {
        return Axios.post(`${apiEndpoint}/admin/shareProject`, { projectID: projectID, sharedUserEmail: email, permission: permission });
    },

    deleteShare: (projectID, sharedUserEmail) => {
        return Axios.post(`${apiEndpoint}/admin/unshareProject`, { projectID: projectID, sharedUserEmail: sharedUserEmail });
    },

    getPermission: (projectID) => {
        return Axios.post(`${apiEndpoint}/admin/getPermission`, { projectID: projectID });
    },

    getUserDetails: () => {
        if (window.location.href.indexOf("http://localhost:8080") != "-1") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\":{\"email\":\"andrinsuter11@gmail.com\",\"username\":\"Andrin Suter\"}\n}'))
        }
        return Axios.post(`${apiEndpoint}/admin/getUserDetails`);
    },

    getCustomerRegistrations: (voucherID) => {

        if (window.location.href.indexOf("http://localhost:8080") != "-1") {
            //eslint-disable-next-line
            return Promise.resolve(JSON.parse('{\n\"data\":{\n    \"success\": true,\n    \"registrations\": [\n        {\n            \"id\": \"29c4247b-b91e-4719-bb94-96325494e935\",\n            \"registration_firstname\": \"Andrin\",\n            \"registration_secondname\": \"Suter\",\n            \"registration_email\": \"andrin.suter@syscam-services.ch\",\n            \"registration_attendants\": 1,\n            \"registration_comment\": \"\",\n            \"date_id\": \"1c63cf47-1d8c-465d-b5be-7e45d89c2240\",\n            \"voucher_id\": \"8d6a1b36-9782-4ac8-abff-95d8d6604b93\",\n            \"registration_timestamp\": \"2021-02-05T21:05:44.483Z\"\n        },\n        {\n            \"id\": \"e051d7aa-d58e-45e9-88ee-d484adaef3a7\",\n            \"registration_firstname\": \"Huber\",\n            \"registration_secondname\": \"asdfasdf\",\n            \"registration_email\": \"sadfsdf@sdf.com\",\n            \"registration_attendants\": 40,\n            \"registration_comment\": \"Ich hätte gern en glüehwi\",\n            \"date_id\": \"a9cb0c8e-cedf-4c73-9edd-8724acce2665\",\n            \"voucher_id\": \"8d6a1b36-9782-4ac8-abff-95d8d6604b93\",\n            \"registration_timestamp\": \"2021-01-29T19:22:36.900Z\"\n        },\n        {\n            \"id\": \"fe08c7ec-3aff-4efc-b6fb-5caf0f3f2637\",\n            \"registration_firstname\": \"Andrin\",\n            \"registration_secondname\": \"Suter\",\n            \"registration_email\": \"andrin.suter@syscam-services.ch\",\n            \"registration_attendants\": 16,\n            \"registration_comment\": \"\",\n            \"date_id\": \"1c63cf47-1d8c-465d-b5be-7e45d89c2240\",\n            \"voucher_id\": \"8d6a1b36-9782-4ac8-abff-95d8d6604b93\",\n            \"registration_timestamp\": \"2021-02-05T21:06:03.465Z\"\n        }\n    ]\n}}'))
        }
        return Axios.post(`${apiEndpoint}/customer/getRegistrations`, { voucherID: voucherID });
    },

    submitCustomerForm: (formDetails) => {
        return Axios.post(`${apiEndpoint}/customer/submitForm`, { firstName: formDetails.firstName, secondName: formDetails.secondName, email: formDetails.email, voucherID: formDetails.voucherID,  comment: formDetails.problem });
    }
}

function triggerLogin(error) {
    if (error.response) {
        if (error.response.status === 401) { apiBus.$emit("showLoginDialog") }
        else if (error.response.status === 404) { apiBus.$emit("showAccessDeniedDialog") }
    }

}