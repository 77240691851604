<template>
  <div class="projectEditor">
    <span class="text-h2">{{ projectName }}</span>

    <RenameProjectMenu
      :opened="renameProjectMenuOpen"
      :name="projectName"
      :id="renameProjectID"
    />
    <EventCalendar
      v-if="dates != null"
      mode="admin"
      :dates="dates"
      :registrations="registrations"
      :editMode="$props.editMode"
    />
    <VoucherPanel
      v-if="vouchers"
      :voucherData="vouchers"
      :editMode="$props.editMode"
    />
    <RegistrationsPanel
      v-if="registrations != null"
      :registrationData="registrations"
      :editMode="$props.editMode"
    />
  </div>
</template>

<script>
import { navBus, projectEditorBus } from "../../main";
import api from "../../assets/js/api";
import EventCalendar from "../../components/Admin/ProjectEditor/EventCalendar";
import VoucherPanel from "../../components/Admin/ProjectEditor/VoucherPanel";
import RegistrationsPanel from "../../components/Admin/ProjectEditor/RegistrationsPanel";
import RenameProjectMenu from "../../components/Admin/ProjectEditor/RenameProjectMenu";

export default {
  data: () => ({
    registrations: null,
    vouchers: null,
    dates: null,
    projectName: null,
    renameProjectMenuOpen: false,
    renameProjectID: "",
    showAccessDeniedDialog: false,
  }),
  props: ["editMode"],
  created() {
    navBus.$emit("changeNavigationTiles", "projectEditor");

    projectEditorBus.$on("deleteRegistration", (registration) => {
      this.deleteRegistration(registration.registration_id);
    });

    projectEditorBus.$on("updateVoucher", (details) => {
      this.updateVoucher(
        details.voucherID,
        details.comment,
        details.submissions
      );
    });

    projectEditorBus.$on("createVoucher", (details) => {
      this.createVoucher(
        this.$route.params.project_id,
        details.comment,
        details.submissions
      );
    });

    projectEditorBus.$on("deleteVoucher", (voucher) => {
      this.deleteVoucher(voucher);
    });

    projectEditorBus.$on("renameProject", (renameDetails) => {
      this.renameProject(renameDetails.projectID, renameDetails.projectName);
    });

    projectEditorBus.$on("changeRenameProjectMenu", (renameDetails) => {
      this.renameProjectMenuOpen = renameDetails.open;
      this.renameProjectID = this.$route.params.project_id;
    });

    projectEditorBus.$on("createDate", (data) => {
      this.createDate(data);
    });

    projectEditorBus.$on("deleteDate", (dateID) => {
      this.deleteDate(dateID);
    });

    projectEditorBus.$on("editDate", (date) => {
      this.editDate(date);
    });

    api.getPermission(this.$route.params.project_id).then((response) => {
      if (response.data) {
        projectEditorBus.$emit("changeEditMode", response.data);
        navBus.$emit("changeEditMode", response.data);
      }
    });

    api
      .getProjectName("projectID", this.$route.params.project_id)
      .then((response) => {
        this.projectName = response.data[0].project_name;
      });

    api.getProjectDates(this.$route.params.project_id).then((projectDates) => {
      this.dates = projectDates.data;
    });

    api
      .getProjectRegistrations(this.$route.params.project_id)
      .then((projectRegistrations) => {
        this.registrations = projectRegistrations.data;
      });

    api
      .getProjectVouchers(this.$route.params.project_id)
      .then((projectVouchers) => {
        this.vouchers = projectVouchers.data;
      });
  },
  components: {
    EventCalendar,
    VoucherPanel,
    RegistrationsPanel,
    RenameProjectMenu,
  },
  methods: {
    deleteRegistration(registrationID) {
      /*  this.registrations = this.registrations.filter((registration) => {
        return registration.registration_id != registrationID;
      });
      projectEditorBus.$emit("changeDeleteRegistrationDialog", false);*/
      api.deleteRegistration(registrationID).then((response) => {
        if (response.data.success) {
          this.registrations = this.registrations.filter((registration) => {
            return registration.registration_id != registrationID;
          });
          projectEditorBus.$emit("changeDeleteRegistrationDialog", false);
        }
      });
    },
    updateVoucher(voucherID, comment, submissions) {
      api.updateVoucher(voucherID, comment, submissions).then((response) => {
        if (response.data.success) {
          projectEditorBus.$emit("changeCreateVoucherDialog", false);
          this.vouchers = response.data.vouchers;
        }
      });
    },
    createVoucher(projectID, comment, submissions) {
      api.createVoucher(projectID, comment, submissions).then((response) => {
        if (response.data.success) {
          projectEditorBus.$emit("changeCreateVoucherDialog", false);
          this.vouchers = response.data.vouchers;
        }
      });
    },
    deleteVoucher(voucher) {
      api.deleteVoucher(voucher).then((response) => {
        if (response.data.success) {
          projectEditorBus.$emit("changeDeleteVoucherDialog", false);
          this.vouchers = response.data.vouchers;
        }
      });
    },
    editDate(date) {
      api
        .updateDate(date.dateID, date.start, date.end, date.attendants)
        .then((response) => {
          if (response.data.success) {
            this.dates = response.data.dates;
            projectEditorBus.$emit("changeNewDateDialog", {
              mode: "create",
              state: false,
            });
          }
        });
    },
    createDate(dateDetails) {
      api
        .createDate(
          dateDetails.projectID,
          dateDetails.startDate,
          dateDetails.endDate,
          dateDetails.attendants
        )
        .then((response) => {
          if (response.data.success) {
            this.dates = response.data.dates;
            projectEditorBus.$emit("changeNewDateDialog", {
              mode: "create",
              state: false,
            });
          }
        });
    },
    deleteDate(dateID) {
      api.deleteDate(dateID).then((response) => {
        if (response.data.success) {
          this.dates = response.data.dates;
          projectEditorBus.$emit("changeEditDateDialog", false);
        }
      });
    },
    renameProject(projectID, projectName) {
      api.updateProject(projectID, projectName).then((response) => {
        if (response.data.success) {
          this.projectName = projectName;
          this.renameProjectMenuOpen = false;
        }
      });
    },
  },
  beforeDestroy() {
    projectEditorBus.$off("createDate");
    projectEditorBus.$off("editDate");
    projectEditorBus.$off("changeEditDateDialog");
    projectEditorBus.$off("deleteDate");
    projectEditorBus.$off("closeNewDateDialog");
    projectEditorBus.$off("changeRenameProjectMenu");
    projectEditorBus.$off("renameProject");
    projectEditorBus.$off("deleteVoucher");
    projectEditorBus.$off("updateVoucher");
    projectEditorBus.$off("createVoucher");
    projectEditorBus.$off("deleteRegistration");
  },
};
</script>

<style>
</style>